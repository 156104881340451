// Configurações globais para plugins
jconfirm.pluginDefaults.useBootstrap = false;
Dropzone.autoDiscover = false;

window.showNotification = function showNotification(message, config = {}) {
    if (!message) {
        throw new Error('Mensagem não informada!');
    }

    if (typeof config !== 'object') {
        throw new Error('Parâmetro de configuração inválido!');
    }

    config.duration = config.duration ?? 8000;
    config.type = config.type ?? 'success';

    if (!['error', 'success', 'warning'].includes(config.type)) {
        throw new Error('Tipo de notificação inválido!');
    }

    const notification = document.getElementById(`${config.type}-notification-template`)
        .content
        .querySelector('.alert')
        .cloneNode(true);

    notification.querySelector('.alert-message').insertAdjacentHTML('beforeend', message);

    const openDialog = document.querySelector('dialog[open]');

    // Se existe uma dialog com o atributo open (aberta), precisamos fazer o append da notificação na dialog
    // caso contrário será exibida atrás do overlay
    if (openDialog) {
        notification.classList.add('absolute', 'right-0', 'bottom-0');
        openDialog.appendChild(notification);

    } else {
        document.querySelector('.notification-container')
            .appendChild(notification);
    }

    setTimeout(() => {
        notification.classList.add('opacity-0', 'invisible');
        setTimeout(() => notification.remove(), 500);
    }, config.duration);
};

window.showAlert = function showAlert (message, config = {}, callback = () => {}) {
    if (!message) throw new Error('É necessário informar a mensagem para o alerta!');

    const defaultType = 'info';
    const configAlertTypes = {
        'danger': {
            type: 'red',
            title: 'Erro!',
            titleColor: 'text-red-500',
            btnClass: 'btn-error'
        },
        'warning': {
            type: 'orange',
            title: 'Atenção!',
            titleColor: 'text-yellow-600',
            btnClass: 'btn-warning'
        },
        'success': {
            type: 'green',
            title: 'Sucesso!',
            titleColor: 'text-green-500',
            btnClass: 'btn-success'
        },
        'info': {
            type: 'blue',
            title: 'Informação!',
            titleColor: 'text-sky-500',
            btnClass: 'btn-info'
        },
    }

    $.alert({
        title: config.title ?? configAlertTypes[config.type ?? defaultType].title,
        titleClass: `!text-2xl ${configAlertTypes[config.type ?? defaultType].titleColor}`,
        content: message,
        type: configAlertTypes[config.type ?? defaultType].type,
        typeAnimated: false,
        bgOpacity: .45,
        autoClose: 'close|8000',
        buttons: {
            close: {
                text: 'OK',
                btnClass: `btn btn-outline btn-md ${configAlertTypes[config.type ?? defaultType].btnClass}`,
                action: callback
            }
        }
    });
};

window.showConfirm = function showConfirm(message, action = () => {}, config = {}) {
    if (!message) throw new Error('É necessário informar a mensagem para o alerta!');
    if (typeof action !== 'function') throw new Error('A ação/função que será executada ao confirmar não é uma função válida!');

    const defaultType = 'info';
    const configAlertTypes = {
        'danger': {
            type: 'red',
            titleColor: 'text-red-500',
            btnClass: 'btn-error'
        },
        'warning': {
            type: 'orange',
            titleColor: 'text-yellow-600',
            btnClass: 'btn-warning'
        },
        'success': {
            type: 'green',
            titleColor: 'text-green-500',
            btnClass: 'btn-success'
        },
        'info': {
            type: 'blue',
            titleColor: 'text-sky-500',
            btnClass: 'btn-info !border-sky-500'
        },
    }

    const buttons = {
        confirm: {
            text: config.confirmButtonLabel ?? 'Confirmar',
            btnClass: `btn btn-outline btn-md ${configAlertTypes[config.type ?? defaultType].btnClass}`,
            action: action
        },
        close: {
            text: config.closeButtonLabel ?? 'Cancelar',
            btnClass: 'btn btn-outline btn-md btn-default'
        }
    };

    $.confirm({
        container: document.querySelector('dialog[open]') ? 'dialog' : 'body',
        title: config.title ?? 'Confirmar?',
        titleClass: `!text-2xl ${configAlertTypes[config.type ?? defaultType].titleColor}`,
        content: message,
        type: configAlertTypes[config.type ?? defaultType].type,
        typeAnimated: false,
        buttons
    })
};

window.Spinner = (function () {
    const spinnerElement = document.createElement('div');
    spinnerElement.className = 'spinner-container';
    spinnerElement.innerHTML = `
        <div class="spinner-body">
            <span class="loading loading-infinity loading-lg"></span> <span class="message">Processando</span>
        </div>
    `;

    let _targetEl = null;

    return {
        show: function (target = null, message = null) {
            if (target) {
                _targetEl = document.querySelector(target);
                _targetEl.appendChild(spinnerElement);

            } else {
                document.body.appendChild(spinnerElement);
            }

            if (message) {
                spinnerElement.querySelector('.message').textContent = message;
            }

            spinnerElement.style.display = 'flex';
        },

        hide: function () {
            spinnerElement.style.display = 'none';

            try {
                _targetEl.removeChild(spinnerElement);

            } catch (error) {
                if (document.body.contains(spinnerElement)) {
                    document.body.removeChild(spinnerElement);
                }
            }
        }
    };
})();

window.printFile = function printFile(pathToFile) {
    let iframe = window._printIframe;

    if (!window._printIframe) {
        window._printIframe = document.createElement('iframe');
        iframe = window._printIframe;
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.onload = function () {
            setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
            }, 1);
        };
    }

    iframe.src = pathToFile;
};

window.getDateFormattedToYMD = function getDateFormattedToYMD(date = new Date()) {
    const year = date.toLocaleString('default', {year: 'numeric'});
    const month = date.toLocaleString('default', {
        month: '2-digit',
    });
    const day = date.toLocaleString('default', {day: '2-digit'});

    return [year, month, day].join('-');
}

window.addDaysToDate = (dateString, days = 1) => {
    const date = new Date(dateString + 'T00:00:00');
    date.setDate(date.getDate() + days);
    return date;
}

window.slugfy = function slugfy(string) {
    if (!string || string.trim() === '' || typeof string !== 'string') {
        throw new Error('Parâmetro inválido. Verifique se o parâmetro é uma string.');
    }

    return string
        .split()
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
}

/**
 * Inicializa o dataTables nas tabelas de listagens
 */
window.initDataTable = function initDataTable(parentEl = '') {
    new DataTable(`${parentEl} table[data-table]`, {
        stateSave: true,
        pagingType: 'full_numbers',
        scrollX: true,
        lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, 'Todos']
        ],
        language: {
            info: 'Página _PAGE_ de _PAGES_ (Total: _MAX_ registros)',
            infoEmpty: 'Nenhum registro encontrado',
            infoFiltered: '- resultado do filtro _TOTAL_ registros',
            lengthMenu: 'Exibir _MENU_ registros por página',
            zeroRecords: 'Nenhum registro encontrado',
            search: 'Aplicar filtro _INPUT_ à tabela',
            paginate: {
                first: 'Primeiro',
                last: 'Último',
                next: 'Próximo',
                previous: 'Anterior'
            }
        },
        responsive: {
            details: {
                type: 'column',
                target: 'tr'
            }
        }
    });
}

window.copyToClipboard = function copyToClipboard(text) {
    if (!text) {
        throw new Error('You must enter the text to be copied as a parameter!');
    }

    if (!navigator.clipboard) {
        throw new Error('Your browser does not support copy to clipboard function!');
    }

    navigator.clipboard.writeText(text)
        .then(function() {
            showNotification('Link copiado copiado com sucesso!', { duration: 5000 });
        })
        .catch(error => {
            showNotification('Não foi possível copiar o link!', { type: 'error' });
            console.error('Async: Could not copy text: ', error)
        });
}

$(function () {
    /**
     * Configura o axios para exibir o loading durante as requisições
     */
    axios.interceptors.request.use(config => {

        if (!config.params || !config.params.withoutSpinner) {
            const openDialog = document.querySelectorAll('dialog[open]');

            if (openDialog.length) {
                const dialog = Array.from(openDialog).pop();
                Spinner.show(`#${dialog.id}`);

            } else {
                Spinner.show();
            }
        }

        return config;
    });

    /**
     * Configura o axios para ocultar o loading durante as respostas
     */
    axios.interceptors.response.use(response => {
        //Só ocultamos o loading caso não seja para fazer redirect na página. Se tiver redirect, deixamos o loading pois
        //no redirect irá ser encerrado.
        if (!response.data.redirect) {
            Spinner.hide();
        }

        return response;
    });

    /**
     * Inicializa o plugin select2
     */
    function initSelect2Plugin(dialog = null) {

        // Essa função corrige o problema com a abertura do select2 dentro de dialogs do HTML
        (function fixSelec2InsideDialogs() {
            function addAttribute(dialog) {
                const select2Inputs = dialog.querySelectorAll('.select2');

                select2Inputs.forEach(input => {
                    input.setAttribute('data-dropdown-parent', `#${dialog.id}`);
                });
            }

            if (dialog) {
                addAttribute(dialog);
            }

            const dialogs = document.querySelectorAll('dialog');
            dialogs.forEach(dialog => addAttribute(dialog));
        })();

        const id = dialog ? '#' + dialog?.id : '';

        //Inicializa elementos select2 em modo select
        $(`${id} select.select2:not(.allow-clear):not(.tags):not(.with-create-tag)`).select2({
            language: 'pt-BR',
            placeholder: 'Selecione...'
        });

        //Inicializa o select2 com a opção de limpar a seleção
        $(`${id} select.select2.allow-clear`).select2({
            language: 'pt-BR',
            allowClear: true,
            placeholder: 'Selecione...'
        });

        // Inicializa elementos select2 em modo tag
        $(`${id} select.select2.tags`).select2({
            language: 'pt-BR',
            createTag: () => null,
            tags: true,
            id: function (object) {
                return object.text;
            },
            placeholder: this?.dataset.placeholder ?? 'Selecione...'
        });

        // Inicializa elementos select2 em modo tag, com a opção de criar tags
        $(`${id} select.select2.tags.with-create-tags`).select2({
            language: 'pt-BR',
            tags: true,
            id: function (object) {
                return object.text;
            },
            placeholder: this?.dataset.placeholder ?? 'Selecione...'
        });

        // Inicializa elementos select2 em modo tag, com a opção de criar novas tags na base de dados via Ajax
        // Necessário remover antes o evento select2:select, pois dentro das dialogs, o mesmo estava sendo duplicado em alguns casos
        $(`${id} select.select2.tags.with-create-tags-with-ajax`).off('select2:select').select2({
            language: 'pt-BR',
            createTag: function (params) {
                return {
                    id: params.term.trim(),
                    text: params.term.trim(),
                    newTag: true
                }
            },
            tags: true,
            id: function (object) {
                return object.text;
            },
            placeholder: 'Selecione ou digite um novo registro...',

        }).on('select2:select', function (event) {
            if (event.params.data.newTag) {
                axios.post(this.dataset.url, { 'descricao': event.params.data.text})
                    .then(response => {
                        if (!response.data.success || !response.data.id) throw new Error('Ocorreu um erro durante a requisição');

                        $(this).find('[value="' + event.params.data.id + '"]')
                            .replaceWith('<option selected value="' + response.data.id + '">' + event.params.data.text + '</option>');

                    })
                    .catch(error => {
                        Spinner.hide();
                        showNotification(error.response.data.message ?? 'Ocorreu um erro durante a requisição!', { type: 'error'});
                    });
            }
        });
    }

    /**
     * Inicializa o plugin do dropzone
     */
    function initDropzone() {
        $('.dropzone:not(.dz-clickable)').each(function (index, el) {
            const parentForm = $(el).parents('form')[0];
            const acceptedFiles = el.dataset.acceptFiles ?? 'image/*';
            const paramName = el.dataset.inputName;
            const multipleFiles = el.hasAttribute('data-multiple-files');
            const previewsContainer = el.querySelector('.previews');
            const maxFilesize = el.dataset.maxFilesize ?? 2; //Em MB
            const message = el.dataset.message ?? (multipleFiles ? 'Clique ou arraste e solte os novos arquivos aqui' : 'Clique ou arraste o arquivo aqui');

            const fileURL = el.dataset.fileUrl;
            const fileSize = el.dataset.fileSize;
            const fileName = el.dataset.fileName;

            if (multipleFiles && (fileURL || fileSize || fileName)) {
                console.warn('A opção "multiplesFiles" é incompatível com as opções "fileURL", "fileSize" e "fileName". As mesmas serão ignoradas.');
            }

            const previewElSrc = parentForm.querySelector(`[data-input-preview=${paramName}]`);

            new Dropzone(el, {
                previewTemplate: $(el).find('template').html(),
                url: parentForm.action,
                maxFilesize,
                maxFiles: multipleFiles ? null : 1,
                acceptedFiles,
                addRemoveLinks: true,
                autoProcessQueue: false,
                previewsContainer,
                paramName,
                createImageThumbnails: !previewElSrc,
                thumbnailWidth: null,
                thumbnailHeight: null,
                thumbnailMethod: 'contain',
                hiddenInputContainer: parentForm,
                dictDefaultMessage: message,
                dictFileTooBig: 'O arquivo é muito grande (@{{filesize}}MB). Tamanho máximo suportado: @{{maxFilesize}}MB.',
                dictInvalidFileType: "Tipo de arquivo não suportado.",
                dictRemoveFile: 'Remover arquivo',
                maxfilesexceeded: function (file) {
                    this.removeAllFiles();
                    this.addFile(file);
                },
                init: function () {
                    if (fileURL) {
                        let mockFile = {name: fileName ?? paramName, size: fileSize ?? 0};
                        this.displayExistingFile(mockFile, fileURL, null, '', false);
                    }

                    this.on("addedfile", file => {
                        // Remove o input fake (se existir) para não exibir as mensagens de validação
                        this.element.querySelector('input.fake-input')?.remove();

                        //remove o input hidden que controla quando apenas vamos remover o arquivo atual
                        parentForm.querySelector(`[name^=remover_${paramName}]`)?.remove();

                        if (previewElSrc) {
                            const src = URL.createObjectURL(file);

                            if (/^video/.test(file.type)) {
                                previewElSrc.innerHTML = `
                                    <video controls class="max-h-72">
                                        <source src="${src}">
                                        Your browser does not support HTML5 video.
                                    </video>
                                `;

                            } else {
                                let classes = '';

                                if (/^image/.test(file.type)) {
                                    classes = 'max-w-full h-auto max-h-72';
                                }

                                if (/pdf$/.test(file.type)) {
                                    classes = 'w-full h-96';
                                }

                                previewElSrc.innerHTML = `
                                    <object data="${src}" type="${file.type}" class="flex flex-col items-center justify-center ${classes}">
                                        <i class="far fa-eye-slash fa-10x text-zinc-400"></i>
                                        <p class="text-zinc-400">Não foi possível realizar o preview para este tipo de arquivo!</p>
                                    </object>
                                `;
                            }
                        }
                    });

                    this.on('removedfile', file => {
                        //Ao remover o arquivo selecionado, cria um input hidden para remover do servidor
                        if (fileURL) {
                            parentForm.insertAdjacentHTML('beforeend', `<input type="hidden" name="remover_${paramName}" value="true">`);
                        }

                        //Caso exista o preview do arquivo selecionado, ao remover o mesmo, limpa o preview
                        previewElSrc && (previewElSrc.innerHTML = '');
                    });
                }
            });
        });

        $('.dropzone-form').off('submit').on('submit', event => {
            event.preventDefault();
            event.stopPropagation();

            const form = event.target;
            const formData = new FormData(form);
            const dropzones = getAllDropzonesInstances(form);

            dropzones.forEach((dropzone, index) => {
                // Se o campo é obrigatório, não existem arquivos selecionados está visível na tela, cria o elemento fake
                // para exibir a mensagem de validação
                if (dropzone.element.hasAttribute('data-required')
                    && !dropzone.files.length && !dropzone.previewsContainer.childElementCount
                    && dropzone.element.checkVisibility()) {
                    const input = document.createElement('input');
                    input.type = 'text';
                    input.name = `fake-input-${index}`;
                    input.required = true;
                    input.classList.add('fake-input');
                    input.style.position = 'absolute';
                    input.style.top = '50%';
                    input.style.left = '0';
                    input.style.right = '0';
                    input.style.opacity = '0';
                    input.autocomplete = 'off';

                    if (dropzone.options.maxFiles === 1) {
                        input.setCustomValidity('Selecione um arquivo!');

                    } else {
                        input.setCustomValidity('Selecione um ou mais arquivos!');
                    }

                    dropzone.element.appendChild(input);
                }

                addDropzoneFilesToFormData(dropzone, formData);

                // let {paramName, maxFiles} = dropzone.options;
                //
                // dropzone.files.forEach((file, index) => {
                //     if (maxFiles === 1) {
                //         formData.append(paramName, file);
                //         return;
                //     }
                //
                //     formData.append(paramName + '[' + index + ']', file);
                // });
            });

            if (!form.checkValidity()) {
                form.reportValidity();

                return;
            }

            axios.post(form.action, formData)
                .then(response => {
                    const {data} = response;

                    if (!data.success) {
                        throw new Error(data.message);
                    }

                    //Se o server enviar o parâmetro para redirecionar a página, redirecionamos para a página
                    //url enviada (redirectTo) ou então atualizamos a url atual
                    if (data.redirect) {
                        window.location.replace(data.redirectTo ?? window.location.href);
                        return;
                    }

                    //Como ocorreu sucesso na requisição e não vai redirecionar a página, alteramos o label do botão de
                    //Cancelar para Fechar
                    $(form).find(':reset').html('<i class="fa fa-times"></i> Fechar');

                    showNotification(data.message ?? 'Sua solicitação foi processada com sucesso!');

                    // Caso não seja para fazer o redirect na página, podemos executar algum código JavaScript utilizando
                    // esse método.
                    window.ajaxSuccessHandler && window.ajaxSuccessHandler();

                })
                .catch(error => {
                    console.error(error.message ?? error.response.data);
                    const message = error.message ?? error.response.data ?? 'Ocorreu um erro ao processar a requisição!';

                    Spinner.hide();
                    showNotification(message, {type: 'error'});
                });
        });
    }

    /**
     * Inicializa o plugin do editor Summernote
     */
    function initSummernote() {
        $('.summernote').summernote({
            lang: 'pt-BR',
            minHeight: 300,
            dialogsFade: true,
            codemirror: {
                theme: 'monokai'
            },
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                ['fontsize', ['fontsize']],
                ['font', ['superscript', 'subscript', 'height']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['insert', ['link', 'picture', 'video', 'table']],
                ['view', ['undo', 'fullscreen', 'codeview', 'help']],
            ],
            fontSizes: ['8', '10', '12', '14', '16', '18', '20', '24', '28', '32', '36', '48', '60', '72'],
            lineHeights: ['0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '1.6', '1.8', '2.0', '3.0'],
            callbacks: {
                onInit: function () {
                    // Configura o posicionamento do textarea para exibir as mensagens de validação do HTML
                    if (this.hasAttribute('required')) {
                        this.style.display = 'block';
                        this.style.position = 'absolute';
                        this.style.opacity = 0;
                        this.style.top = '50%';
                        this.style.left = 0;
                        this.style.right = 0;
                        this.parentElement.style.position = 'relative';
                    }
                }
            }
        });
    }

    /**
     * Aplica as máscaras de formatações necessárias para os inputs
     */
    function applyInputsMasks() {
        /**
         * Formata os inputs com máscara de telefone
         */
        function phoneMask(value) {
            if (!value) return "";

            value = value.replace(/\D/g,'');
            value = value.replace(/(\d{2})(\d)/,"($1) $2");
            value = value.replace(/(\d)(\d{4})$/,"$1-$2");

            return value;
        }

        $('.phone').on('keyup', event => {
            const input = event.target;
            input.value = phoneMask(input.value);
        });

        $('.cns').mask('00.000-0');
    }

    /**
     * Adiciona o evento 'open' nas dialogs (modais)
     */
    function addOpenEventOnDialogs() {
        const OpenEvent = new CustomEvent('open');
        const Observer = new MutationObserver(recs => {
            recs.forEach(({ attributeName, target}) => {
                if (attributeName === 'open' && target.open) {
                    target.dispatchEvent(OpenEvent);
                }
            });
        });

        document.querySelectorAll('dialog').forEach( dialog => {
            Observer.observe(dialog, { attributes: true });
        });
    }

    function getAllDropzonesInstances(form) {
        if (!form) throw new Error('It is necessary to inform the reference form');

        return Array.from(form.querySelectorAll('.dropzone.dz-clickable')).map(dropzone => dropzone.dropzone);
    }

    function addDropzoneFilesToFormData(dropzone, formData) {
        let {paramName, maxFiles} = dropzone.options;

        dropzone.files.forEach((file, index) => {
            if (maxFiles === 1) {
                formData.append(paramName, file);
                return;
            }

            formData.append(paramName + '[' + index + ']', file);
        });
    }

    function scrollToActiveMenu() {
        const nav = document.querySelector('.drawer-side > aside > nav');

        if (!nav) return;

        const activeMenu = nav.querySelector('.active');
        activeMenu.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }

    /**
     * Desabilita os botões dos formulários durante o submit, para evitar múltiplas requisições.
     */
    $(document).on('submit', 'form', event => {
        const form = event.target;
        const buttons = form.querySelectorAll('[type=submit], [type=reset]');
        buttons.forEach(button => button.setAttribute('disabled', 'disabled'));
    })

    /**
     * Controla o comportamento do clique sobre o checkbox para selecionar todos os regitros das listagens.
     */
    $('[name=table_select_all]').on('click', event => {
        const rows = $('table[data-table]').DataTable().rows({'search': 'applied'}).nodes();
        $('[type=checkbox].select-record:not(:disabled)', rows).prop('checked', event.target.checked);
    });

    /**
     * Controla o clique sobre o checkbox de seleção dos registros nas listagens.
     */
    $(document).on('click', '[type=checkbox].select-record', event => {
        const rows = $('table[data-table]').DataTable().rows({'search': 'applied'}).nodes();
        const selectAll = $('[name=table_select_all]');
        const hasAnySelected = $('[type=checkbox].select-record:checked', rows).length;
        const hasAllSelected = !$('[type=checkbox].select-record:not(:checked)', rows).length;

        if (!event.target.checked) {
            selectAll.prop('indeterminate', hasAnySelected);
            selectAll.prop('checked', false);

            return;
        }

        if (hasAllSelected) {
            selectAll.prop('indeterminate', false);
            selectAll.prop('checked', true);

            return;
        }

        selectAll.prop('indeterminate', true);
    });

    /**
     * Controla o comportamento do botão de limpar nos filtros das listas das páginas
     */
    $('.page-filters-form').on('reset', function (event) {
        event.preventDefault();
        $('.page-filters-form select:not(.reset-first)').val(null).change();
        $('.page-filters-form input:not([name=_token])').val(null);

        $('.page-filters-form select.reset-first').each(function() {
            $(this).val($(this).find('option:first').val()).change();
        });
    });

    /**
     * Controla o click sobre os checkboxes das listagens de cadastros
     */
    $(document).on('click', '[data-action=toggle-list-option]', event => {
        const checkbox = event.target;
        const url = checkbox.dataset.url;
        const value = checkbox.checked;
        const setRecordRead = checkbox.dataset.setRecordRead;

        const action = _ => {
            axios.put(url, { value })
                .then(response => {
                    const data = response.data;

                    if (!data.success) {
                        console.error(data.message);
                        checkbox.checked = !checkbox.checked;
                    }

                    showNotification(data.message, { type: data.success ? 'success' : 'error' });

                    if (data.success && setRecordRead !== undefined) {
                        $(checkbox).parents('tr').toggleClass('font-semibold', !checkbox.checked);
                    }

                    // Se deve atualizar algum campo da tela
                    if (data.updateData) {
                        for (const item in data.updateData) {
                            $(checkbox).parents('tr').find(`[data-${item}]`).html(data.updateData[item]);
                        }
                    }

                })
                .catch (error => {
                    console.error(error);
                    Spinner.hide();
                    showNotification('Ocorreu um erro ao processar a requisição', { type: 'error' });
                });
        }

        if (!value && checkbox.attributes.hasOwnProperty('data-requires-confirmation')) {
            const message = checkbox.dataset.confirmMessage ?? 'Deseja realmente prosseguir com a operação?';
            showConfirm(message, action, { type: 'warning' });
            return;
        }

        action();
    });

    /**
     * Controla o click sobre o botão para remover registros
     */
    $(document).on('click', '[data-action=remove-records]', event => {
        const records = [];
        const elementID = event.target.dataset.id;

        if (elementID) {
            records.push({ id: elementID});

        } else {
            $('[type=checkbox].select-record').each((idx, elem) => {
                if (elem.checked) {
                    records.push({ id: elem.value });
                }
            });
        }


        if (!records.length) {
            showAlert('É necessário escolher pelo menos um registro para a exclusão.', { type: 'warning' });
            return;
        }

        const button = event.target;
        const url = button.dataset.url;
        const message  = 'Esta operação irá remover os registros, tem certeza que deseja remover ' + records.length + ' registro(s)?'
        const action = () => {
            axios.post(url, { '_method': 'DELETE', 'registros': records })
                .then(response => {
                    const data = response.data;

                    if (!data.success) {
                        showNotification(data.message, { type: 'error' });
                        return;
                    }

                    window.location.href = data.redirectTo;
                })
                .catch(error => {
                    console.error(error);
                    Spinner.hide();
                    showNotification(error, { type: 'error' });
                });
        }

        showConfirm(message, action, { type: 'danger' });
    });

    /**
     * Controla o click sobre o botão para restaurar registros
     */
    $('[data-action=restore-records]').on('click', event => {
        const records = [];

        $('[type=checkbox].select-record').each((idx, elem) => {
            if (elem.checked) {
                records.push({ id: elem.value });
            }
        });

        if (!records.length) {
            showAlert('É necessário escolher pelo menos um registro para restaurar.', { type: 'warning' });
            return;
        }

        const button = event.target;
        const url = button.dataset.url;
        const message  = 'Esta operação irá restaurar os registros, tem certeza que deseja restaurar ' + records.length + ' registro(s)?'
        const action = () => {
            axios.put(url, { 'registros': records })
                .then(response => {
                    const data = response.data;

                    if (!data.success) {
                        showNotification(data.message, { type: 'error' });
                        return;
                    }

                    window.location.href = data.redirectTo;
                })
                .catch(error => {
                    console.error(error);
                    Spinner.hide();
                    showNotification(error, { type: 'error' });
                });
        }

        showConfirm(message, action, { type: 'warning' });
    });

    /**
     * Controla o click sobre os botões de edição dos registros
     * O evento precisa ser adicionado no document, para funcionar dentro da paginação do dataTables
     */
    $(document).on('click', '[data-action=open-dialog]', event => {
        const el = event.currentTarget;

        if (!el.dataset.dialogId) throw new Error(`É necessário informar o atributo "data-dialog-id" no elemento ${el.outerHTML}!`);

        function initDialogControls() {
            const dialog = document.getElementById(el.dataset.dialogId);

            //remove a dialog do HTML ao fechá-la, para não "poluir" o HTML
            dialog.addEventListener('close', _ => dialog.remove());

            initDropzone();
            initSummernote();
            initSelect2Plugin(dialog);
            applyInputsMasks();
            initDataTable('#' + dialog.id);

            dialog.showModal();
        }

        axios.get(el.dataset.url)
            .then(response => {
                const data = response.data;

                if (!data.success) {
                    throw new Error(data.message);
                }

                if (!data.html || !data.html.length) {
                    throw new Error('Está faltando o HTML de retorno.');
                }

                for (const item in data.updateData ?? {}) {
                    const targetEl = $(el).parents('tr').find(`[data-${item}]`);

                    if (targetEl.is('[type=checkbox]')) {
                        targetEl[0].checked = !!data.updateData[item];

                        targetEl.parents('tr').removeClass(_ => targetEl.is('[data-set-record-read]') ? 'font-semibold' : null);

                    } else {
                        targetEl.html(data.updateData[item]);
                    }
                }

                $('body').append(data.html);
                initDialogControls();

            })
            .catch(error => {
                console.error(error);
                Spinner.hide();
                showNotification(error.message ?? 'Não foi possível concluir a operação. Por favor, tente novamente!', { type: 'error' });
            });
    });

    /**
     * Controla o click sobre o thumb das imagens nas listagens
     */
    $(document).on('click', '[data-action=image-preview]', event => {
        const image = event.target.src;

        const dialog = $(`
            <dialog id="image_preview" class="modal">
                <div class="modal-box w-auto max-w-6xl">
                    <button class="btn-close" onclick="image_preview.close()">✕</button>
                    <div class="modal-body">
                        <h2 class="page-subtitle mb-6">Preview</h2>

                        <img src="${image}" alt="">
                    </div>
                </div>
            </dialog>
        `);

        $('body').append(dialog);

        const dialogEl = dialog.get(0);

        dialogEl.showModal();

        dialogEl.addEventListener('close', _ => dialog.remove());
    });

    /**
     * Controla o submit via Ajax de formulários que não possuem o componente dropzone
     */
    $(document).on('submit', 'form.ajax-form:not(.dropzone-form)', event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.target;
        const formData = new FormData(form);

        axios.post(form.action, formData)
            .then(response => response.data)
            .then(data => {
                if (!data.success) {
                    throw new Error(data.message);
                }

                //TODO: Podemos alterar esse método para manter a mesma lógico dos forms com dropzone (linha 453)
                window.location.replace(data.redirectTo ?? window.location.href);
            })
            .catch(error => {
                console.error(error.response?.data ?? error.message);
                const message = error.response?.data?.message ?? error.message ?? 'Ocorreu um erro ao processar a requisição!';

                showNotification(message, { type: 'error' });
                Spinner.hide();
                form.querySelector('[type=submit]').removeAttribute('disabled');
                form.querySelector('[type=reset]').removeAttribute('disabled');
            });
    });

    /**
     * Inicializa os plugins nos inputs que estão dentro das dialogs, ao abrir as mesmas
     * Importante: Só funciona nas dialogs de inclusão. Na edição a inicialização dos plugins é feita no método
     * "open-dialog".
     */
    $('dialog').on('open', event => {
        initDropzone();
        initSummernote();
        initSelect2Plugin(event.target);
        applyInputsMasks();
    });

    $(document).on('click', '[data-action=preview]', event => {
        event.preventDefault();

        const form = $(event.target).parents('form')[0];
        const serializedData = new FormData(form);
        const url = event.target.dataset.url;

        serializedData.delete('_method');

        const dropzones = getAllDropzonesInstances(form);

        dropzones.forEach(dropzone => {
            addDropzoneFilesToFormData(dropzone, serializedData);
        });

        axios.post(url, serializedData)
            .then(response => {
                const data = response.data;

                if (!data.success) {
                    throw new Error(data.message);
                }

                if (!data.html || !data.html.length) {
                    throw new Error('Está faltando o HTML de retorno.');
                }

                $('body').append(data.html);

                const dialog = document.getElementById(event.target.dataset.dialogId);

                //remove a dialog do HTML ao fechá-la, para não "poluir" o HTML
                dialog.addEventListener('close', _ => dialog.remove());
                dialog.showModal();

            })
            .catch(error => {
                console.error(error);
                Spinner.hide();
                showNotification(error.message, { type: 'error' });
            });
    });

    addOpenEventOnDialogs();
    initDataTable();
    initSelect2Plugin();

    // Retrair o menu na versão desktop
    if (window.innerWidth >= 1280) {
        const menuToggle = $('.menu-toggle');
        const drawer = $('.drawer');
        const drawerSide = $('.drawer-side');
        const menuLateral = $('.menu-lateral');
        const menuLateralNav = $('.menu-lateral > nav');

        menuToggle.removeClass('hidden').addClass('flex');

        if(localStorage.getItem('menuExpanded') === 'false') {
            drawerSide.css('width', 'inherit');
            drawer.addClass('retract');

        } else {
            drawer.removeClass('retract');
            drawerSide.css('width', 'auto');
            localStorage.setItem('menuExpanded', 'true');
        }

        menuToggle.on('click', function() {
            if(localStorage.getItem('menuExpanded') === 'true') {
                localStorage.setItem('menuExpanded', 'false');
                drawerSide.css('width', 'inherit');
                drawer.addClass('retract');
                menuToggle.addClass('open');
            }
            else{
                drawer.removeClass('retract aux');
                menuToggle.removeClass('open');
                drawerSide.css('width', 'auto');
                localStorage.setItem('menuExpanded', 'true');
            }
        })

        menuLateralNav.on('mouseenter', function () {
            if(localStorage.getItem('menuExpanded') === 'false') {
                drawer.removeClass('retract').addClass('aux');
                setTimeout(scrollToActiveMenu, 100);
            }
        })

        menuLateral.on('mouseleave', function(){
            if(localStorage.getItem('menuExpanded') === 'false') {
                drawer.addClass('retract').removeClass('aux');
            }
        })
    }

    scrollToActiveMenu();
});
